<template>
  <div>
    <!-- 公共头部 -->
    <Header title=""></Header>

    <div class="examination-box">
      <div class="view1">
        <div class="input-box">
          <img class="icon" src="@/assets/images/icon80.png" />
          <input
            type="text"
            placeholder="请输入身份证号"
            class="input"
            v-model="cardid"
          />
        </div>
        <div class="submit-box">
          <div class="btn" @click="submit">登录</div>
        </div>
      </div>
      <div class="view2">
        <div class="singlepage" v-html="config.exam_remarks"></div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      cardid: "",
    };
  },
  computed: {
    uid() {
      return this.$store.state.uid;
    },
    config() {
      return this.$store.state.configInfo;
    },
  },
  methods: {
    async submit() {
      var cardidReg =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      var cardid = this.cardid;
      var data = {
        cardid,
      };
      if (data["cardid"].length >= 7 && data["cardid"].length <= 15) {
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.post("/exam/queryExam", data);
        this.$toast.close();
        if (this.$store.state.beta) {
          console.log(
            "考试中心 登录：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          window.localStorage.setItem("cardid", '"' + data["cardid"] + '"');
          this.$store.commit("saveCardId", data["cardid"]);
          this.$router.push("/ExaminationCenter/lst");
        } else {
          this.$messageBox.alert(res.data, "温馨提示");
        }
      } else if (!cardidReg.test(data["cardid"])) {
        this.$messageBox.alert("请输入合法的身份证号！", "温馨提示");
        return false;
      }
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.post("/exam/queryExam", data);
      console.log(res);
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "考试中心 登录：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        window.localStorage.setItem("cardid", '"' + data["cardid"] + '"');
        this.$store.commit("saveCardId", data["cardid"]);
        this.$router.push("/ExaminationCenter/lst");
      } else {
        this.$messageBox.alert(res.data, "温馨提示");
      }
    },
  },
};
</script>

<style scoped>
.examination-box {
  padding-bottom: 0.3rem;
}
.examination-box .view1 {
  padding: 1rem 0.85rem 0;
}
.examination-box .view1 .input-box {
  height: 0.8rem;
  border: 0.02rem solid #b1b1b1;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0 0 0 0.3rem;
  display: flex;
  align-items: center;
}
.examination-box .view1 .input-box .icon {
  width: 0.34rem;
  height: 0.24rem;
}
.examination-box .view1 .input-box .input {
  background: transparent;
  padding: 0 0.2rem;
  width: 5.12rem;
  height: 0.6rem;
  border: none;
  outline: none;
  font-size: 0.28rem;
}
.examination-box .view1 .submit-box {
  margin-top: 1.4rem;
}
.examination-box .view1 .submit-box .btn {
  width: 100%;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121e5f;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.32rem;
  color: #ffffff;
}
.examination-box .view2 {
  margin-top: 0.7rem;
}
.examination-box .view2 .singlepage {
  padding: 0.3rem;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.6rem;
}
</style>